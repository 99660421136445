<template>
  <section 
  
  class="card-container">
    <div
      tabindex="-1"
      :id="isContinueWatch ? `continue_watch_${content.objectid}` : `normal_${content.objectid}`"
      class="card"
      :class="highlight ? borderColor : ''"
      @click="cardClickHandler(content)"
      :style="[{ height: `${cardType.height}`, display: 'flex' }, { 'max-height': `${cardType.maxHeight ? cardType.maxHeight : '100%' }` }, { width: `${cardType.width}` }, { margin: `${cardType.margin}` }]"
    >
      <imageItem class="carousel-image-item" :source="gotPosterItem" :cardType="cardType"/>

      <div
        class="play-img"
        id="play-imgs"
        v-if="cardType.playButton.enablePlay || (screen ? screen.sectionType === 'DIRECTPLAY' : '') || content.category == 'SHORTS'"
      >
        <img
          id="play-img-item"
          src="@/assets/icons/Ic_Play1.svg"
          :width="cardType.playButton.enablePlay ? `${cardType.playButton.width}` : `${cardWidth}`"
          :height="cardType.playButton.enablePlay ? `${cardType.playButton.height}` : `${cardHeight}`"
        />
      </div>

      <div
        class="remove-btn"
        :class="[localDisplayLang === 'ara' ? 'remove-btn-ara' : 'remove-btn-eng']"
        v-if="showRemove"
      >
        <img class="remove-watchlist" src="@/assets/icons/remove-watchlist.svg" @click.stop="removeFromWatchlist(content)" alt />
      </div>

      <div
        class="content-details"
        v-if="showTitle || (screen ? screen.sectionType === 'DIRECTPLAY' : '')"
        :dir="localDisplayLang === 'ara' ? 'rtl' : 'ltr'"
      >
        <div v-if="content.category === 'MOVIE' && content.title && isContinueWatch"></div>
        <div v-if="content.category === 'TVSHOW' && content.seriesname"></div>
      </div>

      <div
        :class="[localDisplayLang === 'ara' ? 'trailer_tags_container_right' : 'trailer_tags_container']"
        v-if="content.contenttype === 'Trailer'"
      >
        <div class="trailer_tags_container_item" dir="ltr">
          <p>{{ calcTimeFormat(content.duration) }}</p>
        </div>
      </div>

      <div class="tags-container" v-if="displayTags">
        <div class="tags-container-item">
          <!--content tag container-->
          <div
            :class="['content-tags']"
            v-if="isObjectTag(content)"
          >
            <div>
            <img v-if="pickTagForContent(content).key == 'PREMIUM'" src="@/assets/icons/Premium_1_scg.svg" alt="crown">

            <p class="extra-tag-feature" v-else> {{ pickTagForContent(content).value }} </p>

            </div>
          </div>
          <div
            :class="[localDisplayLang === 'ara' ? 'category-tags-right' : 'category-tags']"
            v-if="content.objecttype === 'SERIES' && content.seasoncount > 1"
          >
            <div>
              <img src="@/assets/icons/ic_episodes.svg" alt />
              <span v-if="content.objecttype === 'SERIES'">{{ content.seasoncount }}</span>
            </div>
          </div>
        </div>
      </div>

      <div v-if="percentProgress != undefined" class="gray-line" :dir="localDisplayLang === 'ara' ? 'rtl' : 'ltr'"></div>
      <div
        v-if="percentProgress"
        class="progress-percent"
        :style="{ width: percentProgress + '%' }"
        :dir="localDisplayLang === 'ara' ? 'rtl' : 'ltr'"
      ></div>
    </div>

    <div class="triangle" :class="triangleColor" v-if="highlight && !percentProgress"></div>
  </section>
</template>

<script>
import { eventBus } from "@/eventBus";
import Utility from "@/mixins/Utility.js";
import PlaybackUtilities from "@/mixins/playbackUtilities.js";
import { _projectName } from "@/provider-config.js";
import { mapGetters } from "vuex";
import { store } from "@/store/store";

export default {
  props: {
    content: {
      type: Object,
    },
    cardType: {
      type: Object,
    },
    screen: {
      type: Object,
    },
    percentProgress: {
      type: Number,
    },
    absoluteProgress: {
      type: Number,
    },
    id: {
      type: [String, Number],
    },
    showRemove: {
      type: Boolean,
    },
    showTitle: {
      type: Boolean,
    },
    hiddenContent: {
      type: Object,
    },
    displayTags: {
      type: Boolean,
    },
    playerInstance: {
      type: Object,
    },
    isDirectPlay: {
      type: Boolean,
    },
    isContinueWatch: {
      type: Boolean,
    },
  },
  data() {
    return {
      cardHeight: "40px",
      cardWidth: "40px",
      borderColor: null,
      highlight: false,
      cardWidth: "100%",
      gotPosterItem: null,
      triangleColor: null,
      isContentPlayable: false,
      availabilities: [],
      subscriptions: [],
      filteredAvailabilities: [],
      pricemodel: [],
      localDisplayLang: null,
      completeEpisodeListing: [],
      playlistIndex: -1,
      isMpegRequired: false,
      episodeList: [],
      totalEpisodeCount: 0,
      playbackInitiated: false,
      isActiveStatus: true,
      currentContinueContent: '',
      parentalControlsEnabled: false
    };
  },
  watch: {
    subscriptionList(val) {
      if (val) {
        this.subscriptions = val;
        // this.getAvailability(this.content);
      }
    },
    availabilityList(val) {
      if (val) {
        this.availabilities = val;
        // this.getAvailability(this.content);
      }
    },
    content () {
      this.getPoster();
    }
  },
  computed: {
    ...mapGetters(["appConfig", "subscriberid", "availabilityList", "subscriptionList"]),
  },
  created() {
    
    this.isMpegRequired = this.checkMpegRequired();

    this.getPoster();

    if (this.availabilityList !== undefined) {
      this.availabilities = this.availabilityList;
    }

    //assigning subscription list response.
    if (this.subscriptionList !== undefined) {
      this.subscriptions = this.subscriptionList;
    }

    // card border color
    this.borderColor =
      _projectName === "NET5" || _projectName === "VLIVE"
        ? "border-color"
        : _projectName === "NAMMAFLIX"
        ? "border-color-white"
        : "";

    // card triangle Color
    this.triangleColor =
      _projectName === "NET5" || _projectName === "VLIVE"
        ? "triangle-color"
        : _projectName === "NAMMAFLIX"
        ? "triangle-color-white"
        : "";
    eventBus.$on("re-render-carousel", () => {
      this.getPoster();
    });

    this.localDisplayLang = localStorage.getItem("setDisplayLanguageCode");

    eventBus.$on("check-local-storage", (data) => {
      this.localDisplayLang = data;
    });
  },
  mounted() {
    eventBus.$on("off", () => {
      this.highlight = false;
    });

    eventBus.$on(`highlight-${this.id}`, () => {
      this.highlight = true;
    });

    eventBus.$on("player-visibility", (isVisible) => {
      if (isVisible == false && this.playbackInitiated == true) {
        this.playbackInitiated = false;

        this.playerInstance.deregisterEvent("currentcontentended");
        this.playerInstance.deregisterEvent("loadchosencontent");
        this.playerInstance.deregisterEvent("loadmorecontent");
      }
    });
  },
  methods: {
    isObjectTag(content) {
      if (content.hasOwnProperty("objecttag")) {
        if (Array.isArray(content.objecttag)) {
          return content.objecttag.length === 0 ? false : true;
        } else {
          return content.objecttag === null ? false : true;
        }
      }
    },
    // pickTagForContent(tag) {
    //   let contenttags = this.appConfig.contentTags;
    //   if (this.localDisplayLang === "ara") {
    //     return contenttags.ar[`${tag}`];
    //   } else {
    //     return contenttags.en[`${tag}`];
    //   }
    // },
    pickTagForContent(content) {
      if (content.hasOwnProperty("objecttag")) {
        let tag = content.objecttag[0];
        let contenttags = this.appConfig.contentTags;
        if (this.localDisplayLang === "mal") {
          return { key: tag, value: contenttags.ml[`${tag}`]} ;
        } else {
          return { key: tag, value: contenttags.en[`${tag}`]} ;
        }
      }
    },
    cardClickHandler(content) {
      
      // call content detail api 

      if (this.isContinueWatch) {

          let payload = {
            contentid: content.objectid,
            params: {
              displaylanguage: this.localDisplayLang,
            },
          };
          store.dispatch("contentDetail", payload).then((response) => {
          if (!response.data.reason) {
                  this.parentalControlsEnabled = localStorage.getItem("parentalControlEnabled") == 'true' ? true : false;

                if (!this.isContentPlayableParentalLock(this.getCurrentProfile(), response.data.pgrating) && this.isActiveStatus && this.parentalControlsEnabled) {
                eventBus.$emit('event-parental-lock-CB', `continue_watch_${content.objectid}`);
                this.currentContinueContent = content;
                this.isActiveStatus = false
                return;
              } else {
                this.actPlaybackContinueWatch(content);
              }
          }
        })
      }  else {
          this.actPlaybackContinueWatch(content);
      }
    },
    actPlaybackContinueWatch(content) {
      let currentLanguage = this.getCurrentLanguageUrlBase();

      if (content.objecttype == "CONTENT" && this.isDirectPlay) {
        if (this.$router.currentRoute.params && this.$router.currentRoute.params.section) {
          localStorage.setItem("playback_source", this.$router.currentRoute.params.section);
        } else {
          localStorage.setItem("playback_source", "HOME");
        }
        this.playContent(content);
      } else {
        if (screen.width > 0) {
            if(content.category == "TVSHOW"){
             let title = this.spacialCharEncoding(content.defaulttitle || content.title);
            this.$router.push({ name: "detailPage", params: { contentId: content.objectid.toLowerCase() , mediaTitle:title,lang: currentLanguage   } });

          } else if ( content.category == 'SHORTS') {
            let title = this.spacialCharEncoding(content.defaulttitle || content.title);

            this.$router.push({
              name: "sainashorts",
              params: { contentId: content.objectid.toLowerCase(), mediaTitle: title, lang: currentLanguage }
            });
          } else {
           let title = this.spacialCharEncoding(content.defaulttitle || content.title);
            this.$router.push({ name: "detailPageMovie", params: { mediaTitle:title   , contentId: content.objectid.toLowerCase() ,lang: currentLanguage  } });
          }
        }
      }
    },
    openDetailPopup(content) {
      //This statement to prevent click for trailers.
      if (content.contenttype && content.contenttype === "Trailer") return;

      if (
        content.objecttype !== "CONTENT" ||
        content.category === "MOVIE" ||
        (content.category === "TVSHOW" && content.objecttype !== "CONTENT")
      ) {
        let payload = {
          content: content,
          state: true,
        };
        eventBus.$emit("showContentDetail", payload);
        return;
      }
    },
    getPoster() {
      if (this.content.poster && this.content.poster.length) {
        let index = this.content.poster.findIndex((element) => {
          if (this.cardType.type === "LANDSCAPE") {
            return element.postertype === "LANDSCAPE";
          } else if (this.cardType.type === "PORTRAIT") {
            return element.postertype === "PORTRAIT";
          } else if (this.cardType.type === "SQUARE") {
            return element.postertype === "SQUARE";
          }
        });
        if (index > -1) {
          if (screen.width > 768) {
            this.content.poster[index].filelist.filter((el) => {
              //adding thumbnail poster.
              if (this.cardType.quality === "THUMBNAIL" && el.quality === "THUMBNAIL") {
                this.gotPosterItem = el.filename;
              } else {
                if (el.quality === "LOW") {
                  // console.log("SD quality", el.filename);
                  this.gotPosterItem = el.filename;
                } else if (el.quality === "SD") {
                  this.gotPosterItem = el.filename;
                } else if (el.quality === "HD") {
                  this.gotPosterItem = el.filename;
                }
              }
            });
          } else {
            this.content.poster[index].filelist.filter((el) => {
              if (el.quality === "THUMBNAIL") {
                this.gotPosterItem = el.filename;
              }
            });
          }
        } else {
          this.gotPosterItem = this.content.poster[0].filelist && this.content.poster[0].filelist[0] ? this.content.poster[0].filelist[0].filename : ''
        }
      } else if (this.content.watchedduration != undefined || this.content.inwatchlist) {
        this.gotPosterItem = this.content.poster.landscape;
      } else if (this.content.contenttype == "Trailer") {
        let getFilterPoster =
          this.content.poster &&
          this.content.poster.filelist &&
          this.content.poster.filelist.filter((item) => {
            if (item.quality == "THUMBNAIL") {
              return item.filename;
            }
          });
        this.gotPosterItem = getFilterPoster.length && getFilterPoster[0].filename;
        if (!this.gotPosterItem) {
          this.gotPosterItem =
            this.content.poster && this.content.poster.filelist && this.content.poster.filelist[0].filename;
        }
      }
    },
   showPlayer() {
      this.playerInstance.blowUpPlayer();

      this.playerInstance.registerEvent("currentcontentended", this.loadNextContentData);
      this.playerInstance.registerEvent("loadchosencontent", this.loadChosenContentData);
      this.playerInstance.registerEvent("loadmorecontent", this.loadMoreContentData);

      this.playbackInitiated = true;
      eventBus.$emit("player-visibility", true);
    },
    playContent(content) {
      console.log("THE Play Button", this.isDirectPlay);

      if (this.isDirectPlay && this.subscriberid) {
        this.showPlayer();

        if (content.category == "MOVIE") {
          let episodeIndex = 0;
          this.episodeList = [content];
          this.totalEpisodeCount = 1;

          this.loadChosenIndexContent(episodeIndex, this.episodeList, this.totalEpisodeCount, true);
        } else {
          let returnCode = this.fetchEpisodeRecursively(this.fetchEpisodes, content, this.checkEpisodeInList, false);

          returnCode
            .then(() => {
              let episodeIndex = this.getEpisodeIndexInList(content.objectid, this.episodeList);

              this.loadChosenIndexContent(episodeIndex, this.episodeList, this.totalEpisodeCount, true);
            })
            .catch((error) => {
              console.log("Error encountered : ", error);
            });
        }
      } else if (this.subscriberid && !this.isDirectPlay) {
        console.log("THIS IS DIRECT PLAY FROM CARD");
        this.$emit("play", content);
      } else if (!this.subscriberid) {
        //open lookup popup.
        let payload = {
          state: true,
          formType: "lookup",
        };
        eventBus.$emit("authPopup", payload);
      }
    },
    removeFromWatchlist(content) {
      this.$emit("remove", content);
    },
  },
  components: {
    imageItem: () => import(/* Webpack ChunkName: "imageItem" */ "@/components/Templates/imageItem.vue"),
  },
  mixins: [Utility, PlaybackUtilities],
};
</script>

<style lang="scss" scoped>
@import "@/sass/_variables.scss";
@import "./categoryCard.scss"
</style>
